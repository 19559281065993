
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'JoinTheClub',
  methods : {
    joinClub: function() {
        window.open('https://magiceden.io/marketplace/the_bull_club?attributes=%7B"Outfit"%3A%5B"%7B%5C"value%5C"%3A%5C"Iced+Out%5C"%7D"%5D%7D');
    },
    joinToken: function() {
        window.open('https://raydium.io/swap?outputCurrency=26bedy893CHqi5bcuUFhMgD6uTLw9V9iLWKAQjjDjpEA');
    }
  }
});
