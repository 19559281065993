
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePromo',
  props: {
    heading: String,
    content: String
  },
  data() {
    return {
    }
  }
});
