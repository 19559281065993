import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeHero = _resolveComponent("HomeHero")!
  const _component_MarketBoard = _resolveComponent("MarketBoard")!
  const _component_HomePromo = _resolveComponent("HomePromo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomeHero),
    _createVNode(_component_MarketBoard, { ref: "marketBoard" }, null, 512),
    _createVNode(_component_HomePromo, {
      heading: "Supporting each other",
      content: "Ethos"
    })
  ], 64))
}