import { createApp } from 'vue'
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import App from './App.vue'
import HomeTemplate from './templates/Home.vue'
import AboutTemplate from './templates/About.vue'
import ProfileTemplate from './templates/Profile.vue'
import TreasuryNFTGalleryTemplate from './templates/TreasuryNfts.vue'
import MemberDirectoryTemplate from './templates/MemberDirectory.vue'
import PartnerDirectoryTemplate from './templates/PartnerDirectory.vue'
import EnscapeTemplate from './templates/Enscape.vue'
import DisclaimerTemplate from './templates/Disclaimer.vue'
import EasterRevealTemplate from './templates/EasterReveal.vue'
import TokenTemplate from './templates/Token.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomeTemplate,  },
        // { path: '/me', component: ProfileTemplate },
        // { path: '/about', component: AboutTemplate },
        // { path: '/gallery', component: TreasuryNFTGalleryTemplate },
        // { path: '/collection', component: MemberDirectoryTemplate },
        // { path: '/token', component: TokenTemplate },
        // { path: '/partners', component: PartnerDirectoryTemplate },
        // { path: '/ens', component: EnscapeTemplate },
        { path: '/disclaimer', component: DisclaimerTemplate }
        // { path: '/easter-reveal', component: EasterRevealTemplate }
    ]
})

createApp(App)
    .use(router)
    .mount('#app')