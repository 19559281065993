
import { defineComponent } from 'vue';
import { WalletStore } from 'solana-wallets-vue'
import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { getProfilePicture } from '@solflare-wallet/pfp';

import ModalMessage from './components/ModalMessage.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default defineComponent({
  name: 'App',
  data() {
    return {
      appWallet: {
        connected: false,
        walletStore: {},
        base58Address: '',
        solBalance: 0,
        pfpImageData: ''
      },
      headerMenu: [
        {
          label: 'About',
          path: '/about',
          isTargetBlank: false
        },
        // {
        //   label: 'Collection',
        //   path: '/collection',
        //   isTargetBlank: false
        // },
        {
          label: '$ICEDOUT',
          path: '/token',
          isTargetBlank: false
        }
      ]
    }
  },
  components: {
    ModalMessage,
    Header,
    Footer
  },
  methods: {
    updateAppWallet: function(connected: boolean, walletStore: WalletStore, base58: string) {
      this.appWallet.connected = connected;
      this.appWallet.walletStore = walletStore;
      this.appWallet.base58Address = base58;
    },
    getWalletProfilePicture: function(connection: Connection, walletPubkey: PublicKey) {
      var self = this;

      // https://docs.solflare.com/solflare/technical/solana-profile-picture-protocol-integration
      return getProfilePicture(connection, walletPubkey)
        .then((res) => {
          self.appWallet.pfpImageData = res.url;
          return res;
        });
    },
    getSolanaMarketData: function() {
      return fetch('https://api.solscan.io/market?symbol=SOL&cluster=')
      .then(response => response.json())
      .then((res) => {
        var marketCapInBillions = res.data.marketCapFD / LAMPORTS_PER_SOL;

        return {
          marketCapFD: marketCapInBillions,
          marketCapInBillions: res.data.marketCapFD,
          marketCap: '$' + marketCapInBillions.toFixed(2) + 'B',
          marketCapRank: res.data.marketCapRank,
          priceChange24h: res.data.priceChange24h,
          priceUsdt: res.data.priceUsdt,
          volumeUsdt: res.data.volumeUsdt
        };
      })
      .catch(error => console.warn(error));
    },
    getNftCollectionInfo: function(collectionId: string) {
       return fetch('https://api.solscan.io/collection/id?collectionId=' + collectionId + '&cluster=', {
        method: 'GET'
      })
      .then(response => response.json())
      .then((res) => {
        return res.data;
      })
      .catch(error => console.warn(error));
    },
    getNftCollectionAssets: function(collectionId: string, offset: number, limit: number) {
      return fetch('https://api.solscan.io/collection/nft?sortBy=nameDec&collectionId=' + collectionId + '&offset=' + offset + '&limit=' + limit + '&cluster=', {
        method: 'GET'
      })
      .then(response => response.json())
      .then((res) => {
        return res.data;
      })
      .catch(error => console.warn(error));
    },
    getNftCollectionVolumeHistory: function(collectionId: string) {
      return fetch('https://api.solscan.io/collection/volume/history?collectionId=' + collectionId + '&period=7d&cluster=', {
        method: 'GET'
      })
      .then(response => response.json())
      .then((res) => {
        return res.data;
      })
      .catch(error => console.warn(error));
    }
  }
  // watch: {
  //   bullsFetchComplete(isBullsFetchComplete) {
  //     if (isBullsFetchComplete) {
  //       var bulls = useIcedOutAssets(this.$pinia)
  //       bulls.$patch({ assets: this.bullsFetched })
  //     }
  //   }
  // }
});
