import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalMessage = _resolveComponent("ModalMessage")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalMessage, { ref: "modalMessage" }, null, 512),
    _createVNode(_component_Header, {
      menu: _ctx.headerMenu,
      ref: "header"
    }, null, 8, ["menu"]),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer, { ref: "footer" }, null, 512)
  ], 64))
}