
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalMessage',
  data() {
    return {
      showModalMessage: false,
      message: '',
      btnLabel: 'OK',
      btnUrl: ''
    }
  },
  methods: {
    goToPage(pageUrl: string) {
      window.location.href = pageUrl;
    },
    hideModal() {
      this.showModalMessage = false;
    },
    showModal() {
      this.showModalMessage = true;
    },
    toggleModal() {
      this.showModalMessage = !this.showModalMessage;
    },
    alert(msg: string) {
      this.message = msg;
      this.showModal();
    },
    alertWithCta(msg: string, btnLabel: string, btnUrl: string) {
      this.message = msg;
      this.btnLabel = btnLabel;
      this.btnUrl = btnUrl;
      this.showModal();
    }
  }
});
