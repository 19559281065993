
import { defineComponent } from "vue";

import Hero from "../components/Hero.vue";

export default defineComponent({
  name: "DisclaimerTemplate",
  components: {
    Hero,
  },
});
