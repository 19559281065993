export const themes:  { 
    icon: string, 
    label: string, 
    style: {}
    }[] = [
    {
        // Light theme
        icon: '../assets/bull-light.png',
        label: 'Icey',
        style: {
            body: {
                primary: '#363535',
                secondary: '#003c42',
                link: '#706e6e',
                gradient: [
                    '#F4F9F9',
                    '#CCF2F4',
                    '#A4EBF3',
                    '#706e6e'
                ],
                background: "linear-gradient(-55deg, var(--bodyGradientA), var(--bodyGradientB), var(--bodyGradientC), var(--bodyGradientD)) center center fixed",
                rgbaGradientB: 'rgba(204, 242, 244, 0.25)'
            },
            banner: {
                background: 'none'
            },
            button: {
                fill: '#b4d4e1',
                normal: '#323434',
                hover: '#706e6e',
                background: '#9edce3',
                rgbaBackground: 'rgba(158, 220, 227, 0.85)'
            },
            nav: {
                header: {
                    text: '#2c585c',
                    leftLogoFill: '#a9b5b7',
                    mobileText: '#9edce3'
                },
                footer: {
                    gradientA: '#F4F9F9',
                    gradientB: '#2c585c'
                },
                background: 'rgb(226 246 247 / 67%);'
            },
            gallery: {
                text: '#F4F9F9',
                link: '#323434',
                overlay: 'rgba(170, 170, 170, 0.8)'
            },
            memberDirectory: {
                overlay: 'rgba(255, 255, 255, 0.5)'
            },
            partners: {
                title: '#323434',
                text: '#323434',
                textShadow: 'none'
            },
            header: {
                title: '#9edce3',
                h2: '#4f8b8f'
            },
            accordion: {
                title: '#706e6e',
                value: '#706e6e'
            },
            textContent: {
                background: '#71C9CE',
                text: '#F4F9F9',
                titleGradientA: '#F4F9F9',
                titleGradientB: '#CCF2F4',
                titleFill: '#F4F9F9'
            },
            scrollbar: {
                fill: '#71C9CE',
                thumb: '#706e6e'
            }
        }
    },
    {
        // Dark theme
        icon: '../assets/bull-dark.png',
        label: 'Iced',
        style: {
            body: {
                primary: '#bad2e1',
                secondary: '#e3fdfd',
                link: '#0C4C52',
                gradient: [
                    '#6597bb',
                    '#557699',
                    '#426c8f',
                    '#1f446c'
                ],
                background: "url('/assets/bg_dark_compressed.png') center center fixed",
                rgbaGradientB: 'rgba(85, 118, 153, 0.45)'
            },
            banner: {
                background: 'none'
            },
            button: {
                fill: '#bad2e1',
                normal: '#E3FDFD',
                hover: '#0C4C52',
                background: '#252062',
                rgbaBackground: 'rgba(44, 85, 129, 0.65)'
            },
            nav: {
                header: {
                    text: '#bad2e1',
                    leftLogoFill: '#65abdb',
                    mobileText: '#bad2e1'
                },
                footer: {
                    gradientA: '#0E4A55',
                    gradientB: '#bad2e1'
                },
                background: 'rgba(37, 32, 98, 0.67);'
            },
            gallery: {
                text: '#E3FDFD',
                link: '#E3FDFD',
                overlay: 'rgba(44, 85, 129, 0.8)'
            },
            memberDirectory: {
                overlay: 'rgba(0, 0, 0, 0.5)'
            },
            partners: {
                title: '#E3FDFD',
                text: 'white',
                textShadow: '2.2px 2.2px 2.2px #1c1d1e;'
            },
            header: {
                title: '#E3FDFD',
                h2: '#E3FDFD',
            },
            accordion: {
                title: '#E3FDFD',
                value: '#E3FDFD'
            },
            textContent: {
                background: '#2c5581',
                text: '#cbcbcb',
                titleGradientA: '#0E4A55',
                titleGradientB: '#492e54',
                titleFill: '#cbcbcb'
            },
            scrollbar: {
                fill: '#2c5581',
                thumb: '#6597bb'
            }
        }
    }
];