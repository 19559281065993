
import { defineComponent } from 'vue';
import { ref } from 'vue';
import { themes } from '../theme';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
import { initWallet, useWallet, WalletMultiButton, WalletStore } from 'solana-wallets-vue'
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import { Connection, clusterApiUrl, LAMPORTS_PER_SOL } from '@solana/web3.js'

import 'solana-wallets-vue/styles.css'

const wallets = [
  new PhantomWalletAdapter()
]

initWallet({ wallets, autoConnect: true });

// const network = WalletAdapterNetwork.Mainnet;
// const connection = new Connection(clusterApiUrl(network));

export default defineComponent({
  name: 'Header',
  props: {
    menu: { type: Array, required: true }
  },
  components: {
    WalletMultiButton
  },
  data() {
    return {
      scrollPosition: 0,
      hasScrolled: false,
      walletAddress: '',
      walletImageData: '',
      balances: {
        sol: 0
      },
      sidebarMenu: false,
      mobileMenu: false,
      profileMenu: false,
      isLightTheme: true,
      supportFeatures: ref<string[]>([]),
      connectedAddress: ref<string|undefined>(""),
      connectedTns: ref<string|undefined>(""),
      connectedAddressDisplayName: ref<string|undefined>(""),
      connectedBalanceUst: '0',
      connectedBalanceLuna: '0',
      ownedIcedOutBulls: [] as any,
      ownedIcedOutBadges: [] as any
    }
  },
  mounted() {
    // Listen to scroll events
    window.addEventListener('scroll', this.updateScroll);

    // Retrieve selected theme from local storage
    if (localStorage.useLightTheme) {
      this.isLightTheme = localStorage.useLightTheme === 'true';
    }

     // Listen to scroll events
    window.addEventListener('scroll', this.updateScroll);

    // Retrieve selected theme from local storage
    if (localStorage.useLightTheme) {
      this.isLightTheme = localStorage.useLightTheme === 'true';
    }

    // Detect how many IOs they own
    // this.detectIcedOutBullsInConnectedWallet(terraWallet?.terraAddress);

    // // Detect how many IO badges they own
    // this.detectIcedOutBadgesInConnectedWallet(terraWallet?.terraAddress);
  },
  methods: {
    goHome() {
      window.location.href = "/";
    },
    viewMyProfile() {
      window.location.href = "/me";
    },
    goBullClubHome() {
      window.open("https://bull.club/");
    },
    updateScroll() {
       this.scrollPosition = window.scrollY
    },
    hideSidebarMenu() {
      this.sidebarMenu = false;
    },
    showSidebarMenu() {
      this.sidebarMenu = true;
    },
    toggleSidebarMenu() {
      this.hideMobileMenu();
      this.sidebarMenu = !this.sidebarMenu;
    },
    hideMobileMenu() {
      this.mobileMenu = false;
    },
    showMobileMenu() {
      this.mobileMenu = true;
    },
    toggleMobileMenu() {
      this.hideSidebarMenu();
      this.mobileMenu = !this.mobileMenu;
    },
    hideProfileMenu() {
      this.profileMenu = false;
    },
    showProfileMenu() {
      this.profileMenu = true;
    },
    toggleProfileMenu() {
      this.profileMenu = !this.profileMenu;
    },
    toggleTheme() {
      this.isLightTheme = !this.isLightTheme;
    },
    currentThemeIsLightTheme(): boolean {
      return this.isLightTheme;
    },
    activeWalletHasIcedOut() {
      if (this.ownedIcedOutBullsCount) {
        return this.ownedIcedOutBullsCount > 0;
      }
      
      return false;
    },
    activeWalletHasIcedOutBadge() {
      if (this.ownedIcedOutBadgesCount) {
        return this.ownedIcedOutBadgesCount > 0;
      }
      
      return false;
    },
    copyConnectedWalletAddress() {
      if (this.connectedAddress) {
        navigator.clipboard.writeText(this.connectedAddress);
        this.hideProfileMenu();
      }
    }
  },
  computed: {
    solanaWallet: function(): WalletStore {
      return useWallet();
    },
    walletConnected: function(): boolean {
      return this.solanaWallet.connected?.value;
    },
    currentRouteIsHomepage() {
      return this.$router.currentRoute.value.path === "/";
    },
    activeTheme() : any {
      if (this.isLightTheme) {
        return themes[0];
      }
      else {
        return themes[1];
      }
    },
    themeStyle() : any {
      return '<style> ' +
      ' :root { ' +
      ' --bodyBg: ' + this.activeTheme.style.body.background + ';' +
      ' --bodyGradientA: ' + this.activeTheme.style.body.gradient[0] + ';' +
      ' --bodyGradientB: ' + this.activeTheme.style.body.gradient[1] + ';' +
      ' --bodyGradientC: ' + this.activeTheme.style.body.gradient[2] + ';' +
      ' --bodyGradientD: ' + this.activeTheme.style.body.gradient[3] + ';' +

      ' --rgbaBodyGradientB: ' + this.activeTheme.style.body.rgbaGradientB + ';' +

      ' --primary: ' + this.activeTheme.style.body.primary + ';' + 
      ' --secondary: ' + this.activeTheme.style.body.secondary + ';' + 
      ' --link: ' + this.activeTheme.style.body.link + ';' + 

      ' --bannerImgBackground: ' + this.activeTheme.style.banner.background + ';' + 

      ' --buttonFill: ' + this.activeTheme.style.button.fill + ';' +
      ' --buttonNormal: ' + this.activeTheme.style.button.normal + ';' +
      ' --buttonHover: ' + this.activeTheme.style.button.hover + ';' + 
      ' --buttonBg: ' + this.activeTheme.style.button.background + ';' + 
      ' --buttonRgbaBg: ' + this.activeTheme.style.button.rgbaBackground + ';' + 

      ' --headerTitle: ' + this.activeTheme.style.header.title + ';' +
      ' --headerH2: ' + this.activeTheme.style.header.h2 + ';' +

      ' --galleryText: ' + this.activeTheme.style.gallery.text + ';' +
      ' --galleryLink: ' + this.activeTheme.style.gallery.link + ';' +
      ' --galleryOverlay: ' + this.activeTheme.style.gallery.overlay + ';' +

      ' --memberOverlay: ' + this.activeTheme.style.memberDirectory.overlay + ';' +

      ' --partnerTitle: ' + this.activeTheme.style.partners.title + ';' +
      ' --partnerText: ' + this.activeTheme.style.partners.text + ';' +
      ' --partnerTextShadow: ' + this.activeTheme.style.partners.textShadow + ';' +

      ' --accordionTitle: ' + this.activeTheme.style.accordion.title + ';' +
      ' --accordionValue: ' + this.activeTheme.style.accordion.value + ';' +

      ' --contentBg: ' + this.activeTheme.style.textContent.background + ';' +
      ' --contentText: ' + this.activeTheme.style.textContent.text + ';' +
      ' --contentTitleGradientA: ' + this.activeTheme.style.textContent.titleGradientA + ';' +
      ' --contentTitleGradientB: ' + this.activeTheme.style.textContent.titleGradientB + ';' +
      ' --contentTitleFill: ' + this.activeTheme.style.textContent.titleFill + ';' +

      ' --navHeaderText: ' + this.activeTheme.style.nav.header.text + ';' +
      ' --mobileNavHeaderText: ' + this.activeTheme.style.nav.header.mobileText + ';' +
      ' --navLeftLogoFill: ' + this.activeTheme.style.nav.header.leftLogoFill + ';' +
      ' --navFooterGradientA: ' + this.activeTheme.style.nav.footer.gradientA + ';' +
      ' --navFooterGradientB: ' + this.activeTheme.style.nav.footer.gradientB + ';' +
      ' --navBackground: ' + this.activeTheme.style.nav.background + ';' +

      ' --scrollFill: ' + this.activeTheme.style.scrollbar.fill + ';' +
      ' --scrollThumb: ' + this.activeTheme.style.scrollbar.thumb + ';' +
    '}</style>';
    },
    ownedIcedOutBullsCount(): any {
      return this.ownedIcedOutBulls.length;
    },
    ownedIcedOutBadgesCount(): any {
      return this.ownedIcedOutBadges.length;
    }
  },
  watch: {
    scrollPosition(newScrollPosition) {
      if (newScrollPosition > 60) {
        this.hasScrolled = true;
      }
    },
    isLightTheme(newIsLightTheme) {
      localStorage.useLightTheme = newIsLightTheme;
    },
    mobileMenu(newMobileMenu) {
      if (newMobileMenu == true) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
    // walletConnected(isWalletConnected) {
    //   var self = this;
    //   var app = this.$root as any;

    //   if (isWalletConnected) {
    //     var ownerPubKey = self.solanaWallet?.publicKey?.value;
    //     if (ownerPubKey) {
    //       // Get wallet connection status
    //       self.walletAddress = ownerPubKey?.toBase58();
    //       app.updateAppWallet(isWalletConnected, self.solanaWallet, self.walletAddress);

    //       // Get balance
    //       connection.getBalance(ownerPubKey).then((bal) => {
    //         self.balances.sol = (bal / LAMPORTS_PER_SOL);
    //         app.appWallet.solBalance = self.balances.sol;
    //       });

    //       // Get profile picture
    //       app.getWalletProfilePicture(connection, ownerPubKey)
    //       .then((response: any) => {
    //         self.walletImageData = response.url;
    //       });
    //     }
    //   }
    //   else {
    //     self.walletAddress = '';
    //     self.balances.sol = 0;
    //     self.walletImageData = '';

    //     app.appWallet.connected = false;
    //     app.appWallet.base58Address = '';
    //     app.appWallet.solBalance = 0;
    //     app.appWallet.pfpImageData = '';
    //   }

    //   app.updateAppWallet(isWalletConnected, self.solanaWallet, self.walletAddress);
    // }
  }
});
