
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  data() {
    return {
      yearNow: new Date().getFullYear(),
      solScanUrl: 'https://solscan.io/collection/18b4aeb5588821ce49ac1d2f8839950438e5f388d9776186516a18ead2da4144',
      ioTokenBirdEyeUrl: 'https://birdeye.so/token/26bedy893CHqi5bcuUFhMgD6uTLw9V9iLWKAQjjDjpEA?chain=solana',
      iocfUrl: 'https://www.icedoutcoinflip.com/',
      meFilterUrl: 'https://magiceden.io/marketplace/the_bull_club?attributes=%7B%22Outfit%22%3A%5B%22%7B%5C%22value%5C%22%3A%5C%22Iced+Out%5C%22%7D%22%5D%7D'
    }
  },
  methods: {
    discordAlert() {
      if (this.$root)
      {
        var headerComponent = this.$root.$refs.header as any;
        var isVerifiedWallet = headerComponent.activeWalletHasIcedOut();
        if (isVerifiedWallet) {
          window.open("https://discord.gg/UJC6CMMp", '_blank')
        }
        else {
          var modalComponent = this.$root.$refs.modalMessage as any;
          modalComponent.alert("Please connect wallet and make sure your wallet has an IOB");
        }
      }
    }
  }
});
