
import { defineComponent } from 'vue';

import HomeHero from '../components/HomeHero.vue';
import HomePromo from '../components/HomePromo.vue';
import MarketBoard from '../components/MarketBoard.vue';

export default defineComponent({
  name: 'HomeTemplate',
  components: {
    HomeHero,
    HomePromo,
    MarketBoard
  }
});
