
import { defineComponent } from 'vue';

import JoinTheClub from '../components/JoinTheClub.vue';

export default defineComponent({
  name: 'HomeHero',
  components: {
    JoinTheClub
  },
  // script.js
mounted() {
    const movingImages = this.$el.querySelectorAll('.home-hero-logo');
    const containers = this.$el.querySelectorAll('.home-hero-logo-container');

    let mouseX = 0;
    let mouseY = 0;
    let prevMouseX = 0;
    let prevMouseY = 0;

    containers.forEach((container: { addEventListener: any }) => {
        container.addEventListener('mousemove', (e: { clientX: number; clientY: number; }) => {
            mouseX = (e.clientX / window.innerWidth) * 40 - 30; 
            mouseY = (e.clientY / window.innerHeight) * 30 - 15;
        });

        const updateTransform = () => {
            const smoothness = 0.15; // Adjust the smoothness (0.0 to 1.0)
            const deltaX = mouseX - prevMouseX;
            const deltaY = mouseY - prevMouseY;

            prevMouseX += deltaX * smoothness;
            prevMouseY += deltaY * smoothness;

            movingImages.forEach((image: { style: { transform: string; }; }) => {
                image.style.transform = `rotateX(${prevMouseY}deg) rotateY(${prevMouseX}deg)`;
            });

            requestAnimationFrame(updateTransform);
        };

        updateTransform();
    });
  },
  methods: {
    openSolScan: function() {
      window.open("https://solscan.io/");
    }
  },
});
