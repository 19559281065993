
import { defineComponent } from 'vue';
// import { useIcedOutAssets } from '../iced-out-assets-store'

// const SheetReader = require('g-sheets-api'); // Using https://github.com/bpk68/g-sheets-api#readme

export default defineComponent({
  name: 'MarketBoard',
  data() {
    return {
      marketStats: [] as any,
      // options: {
      //   apiKey: 'AIzaSyDqH8EDNEoStQnlSdyXF4d8Klx_mVCjV4M',
      //   sheetId: '16WF1PAhfTzcjFpXu-t6f1uXPapbPU0hK8JRDZ3EFNCk',
      //   sheetName: 'Bullsana',
      //   returnAllResults: true
      // }
    }
  },
  mounted() {
    // var self = this;
    // var app = this.$root as any;
    
    // https://docs.google.com/spreadsheets/d/16WF1PAhfTzcjFpXu-t6f1uXPapbPU0hK8JRDZ3EFNCk/edit#gid=0
    // SheetReader(
    //   self.options,
    //   (      results: any[]) => {
    //     // Next, fill the rest of the rows with the lovely data
    //     results.forEach(function (row: { [x: string]: string; }) {
    //       var txtLabel = row["Item"];
    //       var txtValue = row["Override"];

    //       if (!txtValue || txtValue.length === 0) {
    //         self.marketStats.push({
    //           label: txtLabel,
    //           value: Number(row["SOL"]) + ' SOL'
    //         });
    //       }
    //       else {
    //         self.marketStats.push({
    //           label: txtLabel,
    //           value: txtValue
    //         });
    //       }
    //     });
    //   },
    //   (error: string) => {
    //     console.log("Error with sheets: " + error)
    //   });

    // app.getSolanaMarketData()
    //   .then((response: any) => {
    //     self.marketStats.push({
    //       label: 'SOL/USDT price',
    //       value: '$' + response.priceUsdt
    //     });

    //     self.marketStats.push({
    //       label: 'SOL mcap',
    //       value: response.marketCap
    //     });
    //   });
  },
  computed: {
    // bullsAvailable: function(): boolean {
    //   var bulls = useIcedOutAssets(this.$pinia)

    //   if (bulls.allBulls) {
    //     console.log(bulls.allBulls);
    //     return true;
    //   }

    //   return false;
    // }
  },
  // watch: {
  //   bullsAvailable(isBullsAvailable) {
  //     if (!isBullsAvailable) {
  //       console.log("Assets not available");
  //     }
  //     else {
  //       console.log("Assets available!");
  //     }
  //   }
  // }
});
